import React from 'react';
import { Checkbox } from '@mui/material';

export const getCheckbox = ({ indeterminate, className, ...rest }) => (
  <Checkbox
    icon={(
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
        <path d="M18.3529 3.5L5.64706 3.5C4.18513 3.5 3 4.68513 3 6.14706L3 18.8529C3 20.3149 4.18513 21.5 5.64706 21.5L18.3529 21.5C19.8149 21.5 21 20.3149 21 18.8529L21 6.14706C21 4.68513 19.8149 3.5 18.3529 3.5Z" fill="#fff" />
        <path d="M5.64706 4L18.3529 4C19.5387 4 20.5 4.96127 20.5 6.14706L20.5 18.8529C20.5 20.0387 19.5387 21 18.3529 21L5.64706 21C4.46127 21 3.5 20.0387 3.5 18.8529L3.5 6.14706C3.5 4.96127 4.46127 4 5.64706 4Z" stroke="#707070" />
      </svg>
    )}
    checkedIcon={(
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
        <path d="M18.3529 3.5L5.64706 3.5C4.18513 3.5 3 4.68513 3 6.14706L3 18.8529C3 20.3149 4.18513 21.5 5.64706 21.5L18.3529 21.5C19.8149 21.5 21 20.3149 21 18.8529L21 6.14706C21 4.68513 19.8149 3.5 18.3529 3.5Z" fill="#00925b" />
        <path d="M8.58681 16.7584L5.75781 13.9294L7.17281 12.5164L10.0008 15.3444L17.0718 8.27344L18.4858 9.68744L11.4148 16.7584L10.0008 18.1734L8.58681 16.7584Z" fill="#fff" />
      </svg>
    )}
    className={className}
    indeterminate={indeterminate}
    {...rest}
  />

);
